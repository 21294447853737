import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { RequestService } from "../services/http-service.service";
@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
  id: any = '';
  booking: any = {};
  client: any = {};
  admin: any = {};
  invoice: any = {};

  sale = 0;
  days = 0;
  cleaning = 0;

  price: any = 0;
  full_price = 0;
  breakfast7 = 0;
  breakfast19 = 0;
  price7 = 0;
  price19 = 0;
  items = [];

  date = ''
  taxNumber = ''
  number = ''
  comment = '';

  breakfasts = [];

  bookings = [];

  booking_number = '';
  start_date = '';
  end_date = '';
  obj: any;

  constructor(private fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: RequestService) {
    this.id = route.snapshot.paramMap.get('id');
    //this.id = '641aed1209af24821d21f21f';
    this.init();
  }

  dateFormat(date) {
    return moment(date).format('DD.MM.YYYY')
  }

  async generateDates() {
    let min = moment(this.bookings[0].dateStart);
    let max = moment(this.bookings[0].dateEnd);

    for await (let booking of this.bookings) {
      if (moment(booking.dateStart) < min) min = moment(booking.dateStart);
      if (moment(booking.dateEnd) > max) max = moment(booking.dateEnd);
    }

    this.start_date = min.format('DD.MM.YYYY')
    this.end_date = max.format('DD.MM.YYYY')

  }

  async init() {
    let user = JSON.parse(localStorage.getItem('hotel_user'));

    await this.http.get('invoice/' + this.id).then(async res => {
      this.booking = res.booking;

      this.invoice = res.invoice;
      this.client = res.client;

      if (Object.keys(this.booking).length == 0) {
        this.bookings = res.invoice.bookings;
        this.generateDates();

        let booking_number = [];
        await this.bookings.map(booking => booking_number.push(booking.number));
        this.booking_number = booking_number.join(', ')

      } else {
        this.bookings.push(this.booking);
        this.booking_number = this.booking.number;
      }


      this.http.get("objects/" + this.bookings[0].objectId).then((res) => {
        this.obj = res;
      });

      for await (let booking of this.bookings) {
        booking.days = moment(moment(booking.dateEnd)).diff(moment(booking.dateStart), 'days');
        if (this.invoice.type == 'tage') { booking.days = Number(booking.days) + 1; }

      }

      this.days = moment(moment(this.booking.dateEnd)).diff(moment(this.booking.dateStart), 'days');
      if (this.invoice.type == 'tage') { this.days = Number(this.days) + 1; }

      if (this.invoice.rooms.length > 0) {
        this.booking.rooms = this.invoice.rooms;
      }

      this.date = this.invoice.date;

      let number = this.invoice.number;
      number = number.split("_");

      if (number[number.length - 1].length == 1) number[number.length - 1] = '00' + number[number.length - 1];
      if (number[number.length - 1].length == 2) number[number.length - 1] = '0' + number[number.length - 1];

      this.number = number.join("_")

      this.taxNumber = this.invoice.taxNumber;
      this.sale = this.invoice.sale;
      this.comment = this.invoice.comment;
      this.cleaning = this.invoice.cleaning;
      this.sale = this.invoice.sale;
    })

    if (this.invoice.ver == 1) {
      await this.http.get('clients/' + this.booking.clientId).then(async client => {
        this.client = client;
      })
    } else {
      await this.http.get('clients/' + this.invoice.bookings[0].clientId).then(async client => {
        this.client = client;
      })
    }

    await this.http.get('administrators/' + user.rootId).then(async admin => {
      this.admin = admin;
    })





    this.calculatePrice();

    setTimeout(() => {
      //window.print()
    }, 3500);
  }

  async calculatePrice() {
    this.price = 0;

    for await (let booking of this.bookings) {
      for await (let room of booking.rooms) {

        if (room.type == 'Rent room') {

          let result_price = ((room.price * booking.days) / 107) * 100;
          let price7 = (room.price * booking.days) - result_price;

          this.full_price += Number(result_price);

          this.price7 += Number(price7);

          this.price += result_price;

          this.items.push({
            label: room.name,
            days: booking.days,
            tax: 7,
            price: room.price,
            result_price: result_price
          })

        } else {

          for await (let bed of room.beds) {

            let result_price = ((bed.price * booking.days) / 107) * 100;
            let price7 = (bed.price * booking.days) - result_price;

            this.full_price += Number(result_price);

            this.price7 += Number(price7);

            this.price += result_price;

            this.items.push({
              label: room.name + ', ' + bed.type_name,
              days: booking.days,
              tax: 7,
              price: bed.price,
              result_price: result_price
            })

          }

        }

        if (room.breakfastCount > 0) {

          let days = booking.days;

          if (room.days) days = room.days;

          this.breakfasts.push({
            room: room.name,
            breakfastPrice7: room.breakfastPrice7,
            breakfastPrice19: room.breakfastPrice19,
            breakfast7: Number(room.breakfastPrice7) * Number(room.breakfastCount),
            breakfast19: Number(room.breakfastPrice19) * Number(room.breakfastCount),
            days: days
          })
        }

        console.log('breakfasts', this.breakfasts)
      }
    }

    if (this.sale > 0) {

      let result_price = (this.sale / 107) * 100;
      let price7 = Number(result_price) * 0.07;

      this.full_price -= Number(result_price);

      this.price7 -= Number(price7);

      this.price -= result_price;

      this.items.push({
        label: 'Rabatt',
        days: 1,
        tax: 7,
        price: this.sale * -1,
        result_price: result_price * -1
      })

    }

    for await (let breakfast of this.breakfasts) {

      if (breakfast.breakfast7 > 0) {

        let result_price = ((breakfast.breakfast7 * breakfast.days) / 107) * 100;
        let price7 = (breakfast.breakfast7 * breakfast.days) - result_price;

        this.full_price += Number(result_price);

        this.price7 += Number(price7);

        this.price += result_price;

        this.items.push({
          label: breakfast.room + ', Frühstück 7%',
          days: breakfast.days,
          tax: 7,
          price: breakfast.breakfast7,
          result_price: result_price
        })
      }

      if (breakfast.breakfast19 > 0) {

        let result_price = ((breakfast.breakfast19 * breakfast.days) / 119) * 100;
        let price19 = (breakfast.breakfast19 * breakfast.days) - result_price;

        this.full_price += Number(result_price);

        this.price19 += Number(price19);

        this.price += result_price;

        this.items.push({
          label: breakfast.room + ', Frühstück 19%',
          days: breakfast.days,
          tax: 19,
          price: breakfast.breakfast19,
          result_price: result_price
        })
      }
    }

    if (this.cleaning > 0) {

      let result_price = (this.cleaning / 119) * 100;
      let price19 = (this.cleaning / 119) * 100 * 0.19;

      this.full_price += Number(result_price);

      this.price19 += Number(price19);

      this.price += result_price;

      this.items.push({
        label: 'Sonderleistungen',
        days: 1,
        tax: 19,
        price: this.cleaning,
        result_price: result_price
      })

    }

    console.log(this.items);


    this.full_price = 0;
    for await (let item of this.items) {
      this.full_price += Number(item.result_price);
    }

    this.full_price += this.price7;
    this.full_price += this.price19;

  }

  ngOnInit() { }
}
