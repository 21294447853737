<div class="wrapper">
  <div class="top">
    <div class="block">
      <img *ngIf="obj.logo != ''" src="http://api.belegunsgsplan.com/img/{{obj.logo}}" height="80px">
      <p>{{admin.companyName}} {{admin.street}} {{admin.houseNumber}} {{admin.city}}</p><br>
      <!-- <p>MM Elektrotechnik s.r.o. !!!</p> -->
      <p style="font-size: 14px;" *ngIf="client.company != ''">{{client.company}}</p>
      <p style="font-size: 14px;" *ngIf="client.company == ''">{{client.name}} {{client.surname}}</p>
      <p style="font-size: 14px;">{{client.address}}</p>
      <p style="font-size: 14px;">{{client.postalCode}} {{client.city}}</p>
      <p style="font-size: 14px;">{{taxNumber}}</p>
    </div>

    <div class="block">
      <p><span>Korrektur Nr.</span> <span>{{invoice.korrekt}}</span></p>
      <p><span>Rechnung Nr.</span> <span>{{number}}</span></p>
      <p><span>Buchungsnummer</span> <span>{{booking_number}}</span></p>
      <p><span>Korrekturdatum</span> <span>{{dateFormat(invoice.korrektDate)}}</span></p>
      <p><span>Rechnungsdatum</span> <span>{{dateFormat(date)}}</span></p>
      <p><span>Lieferdatum</span> <span>{{dateFormat(date)}}</span></p>
      <p><span>Ihr Ansprechpartner</span> <span>{{admin.companyContactPerson}}</span></p>
    </div>
  </div>

  <h6 *ngIf="invoice.korrekt == 0"><span>Rechnung Nr. </span> <span> {{number}}</span></h6>
  <h6 *ngIf="invoice.korrekt < 0"><span>Korrektur zu Rechnung Nr. </span> <span> {{number}}</span></h6>

  <p style="margin-bottom:14px;">Sehr geehrte Damen und Herren,</p>

  <p>vielen Dank für Ihre Buchung</p>
  <p *ngIf="bookings.length == 1">für den Zeitraum von {{dateFormat(bookings[0].dateStart)}} bis {{dateFormat(bookings[0].dateEnd)}}</p>
  <p *ngIf="bookings.length > 1">für den Zeitraum von {{start_date}} bis {{end_date}}</p>
  <p style="margin-bottom:14px;">berechnen wir Ihnen wie folgt</p>

  <table>
    <thead>
      <tr class="darkbg">
        <th class="left">Pos.</th>
        <th class="left">Bezeichnung</th>
        <th></th>
        <th>
          <ng-container *ngIf="invoice.type == 'nachte'">Nächte</ng-container>
          <ng-container *ngIf="invoice.type == 'tage'">Tage</ng-container>
        </th>
        <th>Einzelpreis</th>
        <th>Gesamtpreis</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items;let i = index">
        <td class="left">{{i+1}}</td>
        <td class="left">{{item.label}}</td>
        <td>{{item.tax | number:'1.2'}}%</td>
        <td>{{item.days}}</td>
        <td [ngClass]="{'red': item.price < 0}">{{item.price | number:'1.2-2'}} €</td>
        <td [ngClass]="{'red': item.price < 0}">{{item.result_price | number:'1.2-2'}} €</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="left large" style="padding:12px 0;">Summe</td>
        <td class="large" style="padding:12px 0;">{{price | number:'1.2-2'}} €</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="left large">zzgl. Umsatzsteuer 7%</td>
        <td class="large">{{price7 | number:'1.2-2'}} €</td>
      </tr>
      <tr *ngIf="price19 > 0">
        <td></td>
        <td colspan="4" class="left large" style="padding:0 0 12px 0;">zzgl. Umsatzsteuer 19%</td>
        <td class="large" style="padding:0 0 12px 0;">{{price19 | number:'1.2-2'}} €</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="4" class="left darkbg xlarge">Rechnungsbetrag</td>
        <td class="darkbg xlarge">{{full_price | number:'1.2-2'}} €</td>
      </tr>
      <tr>
        <td></td>
        <td colspan="5" class="left" style="padding:12px 0;">{{comment}}</td>
      </tr>
    </tbody>
  </table>

  <p>Zahlungsbedingungen: Sofort ab Rechnungseingang<br>ohne Abzüge.</p>
  <p style="padding:12px 0">Mit freundlichen Grüßen</p>
  <p>{{admin.companyName}}</p>

  <div class="info">
    <div class="item">
      <br>
      <p>{{admin.companyName}}</p>
      <p>{{admin.street}} {{admin.houseNumber}}</p>
      <p>{{admin.postalCode}} {{admin.city}}</p>
      <p>{{admin.country}}</p>
      <p>{{admin.registrationNumber}}</p>
      <p>Tel.: {{admin.phone}}</p>
    </div>
    <div class="item">
      <p>Bankverbindung</p>
      <p>{{admin.bankName}}</p>
      <p>IBAN {{admin.iban}}</p>
      <p>BIC {{admin.bic}}</p>
      <br>
      <p>Ust.-ID-Nr.: {{admin.vatNumber}}</p>
      <p>St. Nr.: {{admin.taxNumber}}</p>
    </div>
  </div>
</div>