import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { RequestService } from './services/http-service.service';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, public route: ActivatedRoute, public router: Router, public request: RequestService) {


    moment.locale('en')

    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('auth/login');
    }

    //this.router.navigateByUrl('/bookings');
  }

}
