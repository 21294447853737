import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RequestService } from "../../../src/app/services/http-service.service";
@Component({
  selector: "app-iframe",
  templateUrl: "./iframe.component.html",
  styleUrls: ["./iframe.component.scss"],
})
export class IframeComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  id: any = '';
  amount = 0;
  object: any = { rootId: '', message: '' };

  selectedRoom: any = { breakfast: '0' }

  booking = {
    roomId: '',
    dateStart: '',
    dateEnd: '',
    name: '',
    surname: '',
    phone: '',
    email: '',
    company: '',
    address: '',
    postalCode:'',
    city:'',
    comment: '',
    breakfast: '0',
    objectId: '',
    guests: 1,
    rootId: ''
  }

  show_message = false;
  error = false;

  guests_array = [];

  public rooms: any = [];

  constructor(private fb: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public http: RequestService) {
    this.id = route.snapshot.paramMap.get('id');
    this.booking.objectId = this.id;
    this.init();
  }

  async init() {
    await this.http.get('objects/' + this.id).then(object => {
      this.object = object
      this.booking.rootId = object.userId;
    });

    await this.http.get('rooms/' + this.id).then(rooms => this.rooms = rooms);
    this.selectedRoom = this.rooms[0];
    this.booking.roomId = this.rooms[0]._id;

    this.selectRoom();
  }

  selectFirstDate() {
    document.getElementById("secondDate").focus();
  }

  send() {
    console.log(this.booking);

    if (
      this.booking.dateStart == '' ||
      this.booking.dateEnd == '' ||
      this.booking.name == '' ||
      this.booking.surname == '' ||
      this.booking.phone == '' ||
      this.booking.email == '' ||
      this.booking.dateEnd == ''
      ) {
      return this.error = true
    } else {
      this.error = false;
    }

    this.http.post('requests', this.booking).then(res => {
      this.show_message = true;
    })
  }

  selectRoom() {
    this.amount = 0;
    this.guests_array = [];
    this.booking.breakfast = '0';

    this.rooms.forEach(async room => {
      if (room._id == this.booking.roomId) {
        this.selectedRoom = room;

        for await (let bed of room.beds) {
          this.amount += Number(bed.peoples);
        }

        for (let i = 1; i <= this.amount; i++) {
          this.guests_array.push(i);
        }

      }
    })
  }

  ngOnInit() { }
}
