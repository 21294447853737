import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  {
    path: "bedtypes",
    loadChildren: () => import("../../components/bedtypes/bedtypes.module").then((m) => m.BedtypesModule),
  },
  {
    path: "addroom",
    loadChildren: () => import("../../components/addroom/addroom.module").then((m) => m.AddroomModule),
  },
  {
    path: "addobject",
    loadChildren: () => import("../../components/addobject/addobject.module").then((m) => m.AddobjectModule),
  },
  {
    path: "editobject",
    loadChildren: () => import("../../components/editobject/editobject.module").then((m) => m.EditobjectModule),
  },
  {
    path: "objects",
    loadChildren: () => import("../../components/objects/objects.module").then((m) => m.ObjectsModule),
  },
  {
    path: "workers",
    loadChildren: () => import("../../components/workers/workers.module").then((m) => m.WorkersModule),
  },
  {
    path: "rooms",
    loadChildren: () => import("../../components/rooms/rooms.module").then((m) => m.RoomsModule),
  },
  {
    path: "editroom",
    loadChildren: () => import("../../components/editroom/editroom.module").then((m) => m.EditroomModule),
  },
  {
    path: "addworker",
    loadChildren: () => import("../../components/addworker/addworker.module").then((m) => m.AddworkerModule),
  },
  {
    path: "editworker",
    loadChildren: () => import("../../components/editworker/editworker.module").then((m) => m.EditworkerModule),
  },
  {
    path: "admins",
    loadChildren: () => import("../../components/admins/admins.module").then((m) => m.AdminsModule),
  },
  {
    path: "addadmin",
    loadChildren: () => import("../../components/addadmin/addadmin.module").then((m) => m.AddadminModule),
  },
  {
    path: "editadmin",
    loadChildren: () => import("../../components/editadmin/editadmin.module").then((m) => m.EditadminModule),
  },
  {
    path: "bookings",
    loadChildren: () => import("../../components/bookings/bookings.module").then((m) => m.BookingsModule),
  },
  {
    path: "addbooking",
    loadChildren: () => import("../../components/addbooking/addbooking.module").then((m) => m.AddbookingModule),
  },
  {
    path: "addbooking/:requestId",
    loadChildren: () => import("../../components/addbooking/addbooking.module").then((m) => m.AddbookingModule),
  },
  {
    path: "clients",
    loadChildren: () => import("../../components/clients/clients.module").then((m) => m.ClientsModule),
  },
  {
    path: "editbooking/:id",
    loadChildren: () => import("../../components/editbooking/editbooking.module").then((m) => m.EditbookingModule),
  },
  {
    path: "clientbooking",
    loadChildren: () => import("../../components/clientbooking/clientbooking.module").then((m) => m.ClientbookingModule),
  },
  {
    path: "dash",
    loadChildren: () => import("../../components/calendar/calendar.module").then((m) => m.CalendarModule),
  },
  {
    path: "object/:id",
    loadChildren: () => import("../../components/object/object.module").then((m) => m.ObjectModule),
  },
  {
    path: "iframe",
    loadChildren: () => import("../../iframe/iframe.module").then((m) => m.IframeModule),
  },
  {
    path: "settings",
    loadChildren: () => import("../../components/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "invoice1",
    loadChildren: () => import("../../invoice/invoice.module").then((m) => m.InvoiceModule),
  },
  {
    path: "invoices",
    loadChildren: () => import("../../components/invoices/invoices.module").then((m) => m.InvoicesModule),
  },
  {
    path: "addinvoice/:id",
    loadChildren: () => import("../../components/addinvoice/addinvoice.module").then((m) => m.AddinvoiceModule),
  },
  {
    path: "addinvoice",
    loadChildren: () => import("../../components/addinvoice/addinvoice.module").then((m) => m.AddinvoiceModule),
  },
  {
    path: "editinvoice/:id",
    loadChildren: () => import("../../components/editinvoice/editinvoice.module").then((m) => m.EditinvoiceModule),
  },
  {
    path: "archiv",
    loadChildren: () => import("../../components/archiv/archiv.module").then((m) => m.ArchivModule),
  },




  {
    path: 'components',
    loadChildren: () => import("../../components/components/components.module").then(m => m.ComponentsModule)
  },
  {
    path: 'feelings',
    loadChildren: () => import("../../components/feelings/feelings.module").then(m => m.FeelingsModule)
  },
  {
    path: 'effects',
    loadChildren: () => import("../../components/effects/effects.module").then(m => m.EffectsModule)
  },
  {
    path: 'actions',
    loadChildren: () => import("../../components/actions/actions.module").then(m => m.ActionsModule)
  },
  {
    path: 'symptoms',
    loadChildren: () => import("../../components/symptoms/symptoms.module").then(m => m.SymptomsModule)
  },
  {
    path: 'ratings',
    loadChildren: () => import("../../components/ratings/ratings.module").then(m => m.RatingsModule)
  },
  {
    path: 'faq',
    loadChildren: () => import("../../components/faqs/faqs.module").then(m => m.FaqsModule)
  },
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },
  {
    path: 'products',
    loadChildren: () => import("../../components/products/products.module").then(m => m.ProductsModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import("../../components/sessions/sessions.module").then(m => m.SessionsModule)
  },
  {
    path: 'pushes',
    loadChildren: () => import("../../components/pushes/pushes.module").then(m => m.PushesModule)
  }
];
