<!-- <div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
    </div>
  </div>
</div> -->


<div class="form" [ngClass]="{'success' : show_message == true}" style="display: flex; flex-direction: column;">

  <ng-container *ngIf="!show_message">


    <div class="row" *ngIf="object.breakfast == '1'">
      <div class="item">
        <label>Gästeanzahl *</label>
        <input type="number" [(ngModel)]="booking.guests">
      </div>
      <div class="item">
        <label>Frühstück</label>
        <select [(ngModel)]="booking.breakfast">
          <option value="0">Nein</option>
          <option value="1">Ja</option>
        </select>
      </div>
    </div>

    <div class="item" *ngIf="object.breakfast == '0'">
      <label>Gästeanzahl *</label>
      <input type="number" [(ngModel)]="booking.guests">
    </div>
    
    <div class="row">
      <div class="item">
        <label>Anreise *</label>
        <input type="date" [(ngModel)]="booking.dateStart" onfocus="this.showPicker()" (change)="selectFirstDate()">
      </div>
      <div class="item">
        <label>Abreise *</label>
        <input type="date" [(ngModel)]="booking.dateEnd" onfocus="this.showPicker()" [min]="booking.dateStart"
          id="secondDate">
      </div>
    </div>
    <div class="row">
      <div class="item">
        <label>Vorname *</label>
        <input type="text" [(ngModel)]="booking.name" placeholder="name">
      </div>
      <div class="item">
        <label>Nachname *</label>
        <input type="text" [(ngModel)]="booking.surname" placeholder="surname">
      </div>
    </div>
    <div class="item">
      <label>Telefon *</label>
      <input type="text" [(ngModel)]="booking.phone" placeholder="phone">
    </div>
    <div class="item">
      <label>Email *</label>
      <input type="text" [(ngModel)]="booking.email" placeholder="email">
    </div>
    <div class="item">
      <label>Firma (optional)</label>
      <input type="text" [(ngModel)]="booking.company" placeholder="company">
    </div>
    <div class="item">
      <label>Strasse Hausnummer (optional)</label>
      <input type="text" [(ngModel)]="booking.address" placeholder="Adresse">
    </div>
    <div class="row">
      <div class="item">
        <label>Plz (optional)</label>
        <input type="text" [(ngModel)]="booking.postalCode" placeholder="Plz">
      </div>
      <div class="item">
        <label>Ort (optional)</label>
        <input type="text" [(ngModel)]="booking.city" placeholder="Ort">
      </div>
    </div>
    <div class="item">
      <label>Nachricht (optional)</label>
      <input type="text" [(ngModel)]="booking.comment" placeholder="Nachricht schreiben">
    </div>

    <p class="danger" *ngIf="error == true">Füllen Sie alle erforderlichen Felder aus!</p>
    <div class="item">
      <button (click)="send()">Anfrage senden</button>
    </div>

  </ng-container>

  <h3 *ngIf="show_message == true">{{object.message}}</h3>
</div>